<template>
  <span>
    <div class="col-12 md:col-12">
      <h6 id="cabecalho">CODATA - PBCONSIG</h6>
      <br />
      <h6 id="cabecalho2">
        Gerado em {{ dataAtual.toLocaleDateString('pt-BR') }}
      </h6>
    </div>
  </span>
</template>

<script>
export default {
  name: 'CabecalhoRelatorio',

  data() {
    return {
      dataAtual: new Date(),
    }
  },
}
</script>

<style lang="scss" scoped>
#cabecalho {
  font-weight: bold;
}

@media print {
  #cabecalho {
    font-weight: bold;
  }
}
</style>
