<template>
  <span>
    <h3 id="titulo" class="text-center">
      Relatório de Reserva Cartão - Margens
    </h3>
    <div>
      <table id="tabela">
        <thead>
          <tr>
            <th>Entidade</th>
            <th>Matrícula</th>
            <th>Servidor</th>
            <th>CPF</th>
            <th>Rubrica</th>
            <th>Atividade</th>
            <th>Reserva</th>
            <th>T. Reservas</th>
            <th>Margem</th>
            <th>Máx. Lanç</th>
            <th>P. Rs.</th>
            <th>P. T. Rs.</th>
            <th>Dvg. Rs.</th>
            <th>Dvg. Rs. T.Rs.</th>
            <th>Dvg. Lç. Mg.</th>
            <th>Tipo Lançamento</th>
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="(data, index) in relatorioStore.response"
            :key="data.cpf + '-' + index">
            <td>{{ data.nomeEntidade | limitadorString(16) }}</td>
            <td>{{ data.matricula }}</td>
            <td>{{ data.servidor | limitadorString(24) }}</td>
            <td>{{ data.cpf | mascaraCpf }}</td>
            <td>{{ data.rubrica }}</td>
            <td>{{ data.atividade }}</td>
            <td>
              <span v-if="relatorioStore.contentType == 'PDF'">
                {{ data.valorReserva | formatarValor }}
              </span>
              <span v-else>
                {{ data.valorReserva | formatarValorSemCifrao }}
              </span>
            </td>
            <td>
              <span v-if="relatorioStore.contentType == 'PDF'">
                {{ data.totalReservas | formatarValor }}
              </span>
              <span v-else>
                {{ data.totalReservas | formatarValorSemCifrao }}
              </span>
            </td>
            <td>
              <span v-if="relatorioStore.contentType == 'PDF'">
                {{ data.margemDisponivel | formatarValor }}
              </span>
              <span v-else>
                {{ data.margemDisponivel | formatarValorSemCifrao }}
              </span>
            </td>
            <td>
              <span v-if="relatorioStore.contentType == 'PDF'">
                {{ data.maximoLancavel | formatarValor }}
              </span>
              <span v-else>
                {{ data.maximoLancavel | formatarValorSemCifrao }}
              </span>
            </td>
            <td>
              <span v-if="relatorioStore.contentType == 'PDF'">
                {{ data.proporcaoReserva | formataPorcentagem('PDF') }}
              </span>
              <span v-else>
                {{ data.proporcaoReserva | formataPorcentagem('EXCEL') }}
              </span>
            </td>
            <td>
              <span v-if="relatorioStore.contentType == 'PDF'">
                {{ data.proporcaoTotalReservas | formataPorcentagem('PDF') }}
              </span>
              <span v-else>
                {{ data.proporcaoTotalReservas | formataPorcentagem('EXCEL') }}
              </span>
            </td>
            <td>
              <span v-if="relatorioStore.contentType == 'PDF'">
                {{ data.divergenciaReserva | formatarValor }}
              </span>
              <span v-else>
                {{ data.divergenciaReserva | formatarValorSemCifrao }}
              </span>
            </td>
            <td>
              <span v-if="relatorioStore.contentType == 'PDF'">
                {{ data.divergenciaReservaTotalReservas | formatarValor }}
              </span>
              <span v-else>
                {{
                  data.divergenciaReservaTotalReservas | formatarValorSemCifrao
                }}
              </span>
            </td>
            <td>
              <span v-if="relatorioStore.contentType == 'PDF'">
                {{ data.divergenciaLancavelMargem | formatarValor }}
              </span>
              <span v-else>
                {{ data.divergenciaLancavelMargem | formatarValorSemCifrao }}
              </span>
            </td>
            <td class="col_100">{{ data.tipoLancamento }}</td>
          </tr>
        </tbody>
      </table>
    </div>
  </span>
</template>
<script>
import { storeRelatorio } from '@/stores/relatorio'

export default {
  setup() {
    const relatorioStore = storeRelatorio()

    return { relatorioStore }
  },

  mounted() {
    if ('EXCEL' === this.relatorioStore.contentType) {
      this.$emit('gerarExcel')
    } else {
      this.$emit('gerarPDF')
    }
  },
}
</script>
<style lang="scss" scoped>
#titulo {
  font-weight: bold;
}

.formatarValor {
  text-align: right !important;
}

#tabela {
  border-collapse: collapse;
  width: 100%;
  font-size: 0.675rem;
}
#tabela th {
  background-color: #333;
  color: #fff;
  padding: 4%;
  text-align: center;
}

#tabela td {
  text-align: center;
  padding: 4%;
  border-bottom: 1px solid #ddd;
}

#tabela tbody tr:nth-child(even) {
  background-color: #f2f2f2;
}

#tabela tfoot {
  background-color: #666666;
  color: #fff;
}

@media print {
  #titulo {
    font-weight: bold;
  }

  .formatarValor {
    text-align: right !important;
  }

  #tabela {
    border-collapse: collapse;
    width: 100%;
    font-size: 0.675rem;
  }
  #tabela th {
    background-color: #333;
    color: #fff;
    padding: 10px;
    text-align: left;
  }

  #tabela td {
    padding: 10px;
    border-bottom: 1px solid #ddd;
  }

  #tabela tbody tr:nth-child(even) {
    background-color: #f2f2f2;
  }

  #tabela tfoot {
    background-color: #666666;
    color: #fff;
  }
}
</style>
