<template>
  <Card v-show="false">
    <template #content>
      <div class="grid">
        <div class="col-1 md:col-1"></div>
        <div id="relatorio" class="col-10 md:col-10">
          <Cabecalho />
          <component
            :is="relatorioStore.nomeRelatorio"
            @gerarPDF="gerarPDF"
            @gerarExcel="gerarExcel"></component>
        </div>
        <div class="col-1 md:col-1"></div>
      </div>
    </template>
  </Card>
</template>

<script>
import * as XLSX from 'xlsx'
import { saveAs } from 'file-saver'
import { jsPDF } from 'jspdf'
import { storeRelatorio } from '@/stores/relatorio'
import 'jspdf-autotable'
import Cabecalho from '@/components/shared/relatorio/cabecalhos/CabecalhoGenerico.vue'
import ConciliacaoDeRetorno from '@/components/shared/relatorio/movimento/ConciliacaoDeRetorno.vue'
import ExtratoConsignacao from '@/components/shared/relatorio/movimento/ExtratoConsignacao.vue'
import Consignacao from '@/components/shared/relatorio/movimento/Consignacao.vue'
import PrestacoesDescontadas from '@/components/shared/relatorio/movimento/PrestacoesDescontadas.vue'
import ReservaCartao from '@/components/shared/relatorio/movimento/ReservaCartao.vue'
import ReservaCartaoMargens from '@/components/shared/relatorio/movimento/ReservaCartaoMargens.vue'
import RelatorioCobranca from '@/components/shared/relatorio/financeiro/RelatorioCobranca.vue'
import TaxaAssistencial from '@/components/shared/relatorio/financeiro/TaxaAssistencial.vue'

export default {
  name: 'TemplateRelatorio',

  components: {
    Cabecalho,
    ConciliacaoDeRetorno,
    ExtratoConsignacao,
    Consignacao,
    PrestacoesDescontadas,
    ReservaCartao,
    ReservaCartaoMargens,
    RelatorioCobranca,
    TaxaAssistencial,
  },

  setup() {
    const relatorioStore = storeRelatorio()
    return { relatorioStore }
  },

  methods: {
    async gerarPDF() {
      const doc = new jsPDF('l', 'pt', 'a4')

      doc.setFontSize(8)
      doc.setFont('helvetica', 'bold')
      const cabecalho = document.getElementById('cabecalho').innerHTML
      const cabecalho2 = document.getElementById('cabecalho2').innerHTML
      doc.text(15, 30, cabecalho, {
        margin: { top: 10, right: 10, bottom: 10, left: 10 },
        padding: 5,
      })
      doc.setFontSize(7)
      doc.text(13, 40, cabecalho2, {
        margin: { top: 10, right: 10, bottom: 10, left: 10 },
        padding: 5,
      })

      const titulo = document.getElementById('titulo').innerHTML
      doc.setFont('helvetica', 'bold')
      doc.setFontSize(14)
      doc.text(doc.internal.pageSize.getWidth() / 2, 70, titulo, {
        align: 'center',
        margin: { top: 10, right: 10, bottom: 10, left: 10 },
        padding: 5,
      })

      doc.autoTable({
        html: '#tabela',
        startY: 100,
        useCss: true,
        theme: 'plain',
        styles: {
          fontSize: 7,
          halign: 'center',
          valign: 'middle',
          fillColor: [255, 255, 255],
          overflow: 'hidden',
          cellPadding: 0.5,
        },
        headStyles: {
          fillColor: [0, 121, 182],
          textColor: [255, 255, 255],
        },
        alternateRowStyles: {
          fillColor: [240, 240, 240],
        },
        showFoot: 'lastPage',
        didDrawPage: function (data) {
          let str = 'Página ' + doc.internal.getNumberOfPages()
          doc.setFontSize(10)
          let pageSize = doc.internal.pageSize
          let pageHeight = pageSize.height
            ? pageSize.height
            : pageSize.getHeight()
          doc.text(str, data.settings.margin.left, pageHeight - 10)
        },
      })

      const filename = this.relatorioStore.nomeRelatorio + '.pdf'
      doc.save(filename)
    },

    async gerarExcel() {
      const table = document.getElementById('tabela')
      const wb = XLSX.utils.table_to_book(table)
      const ws = wb.Sheets[wb.SheetNames[0]]

      ws['!cols'] = [
        { wpx: 64 },
        { wpx: 120 },
        { wpx: 40 },
        { wpx: 100 },
        { wpx: 40 },
        { wpx: 60 },
        { wpx: 120 },
        { wpx: 80 },
      ]

      const wbout = XLSX.write(wb, {
        bookType: 'xlsx',
        type: 'array',
      })

      const blob = new Blob([wbout], {
        type: 'application/octet-stream',
      })

      saveAs(blob, `${this.relatorioStore.nomeRelatorio}.xlsx`)
    },
  },
}
</script>
